// TODO: remove duplicated code
.select-wrapper {
  margin-bottom: 1.5rem;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  & select {
    border-radius: 0.8rem;
    height: 5.6rem;
    border: 0.1rem solid var(--kt-primary-light);
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100% !important;

    &:focus {
      outline: none !important;
      border-color: var(--kt-primary);
    }
  }

  & label {
    position: absolute;
    font-size: 1.4rem !important;
    top: -1rem;
    left: 1rem;
    color: var(--kt-primary);
    background-color: var(--kt-white);
    padding: 0 0.5rem;
  }

  & .required-input {
    color: var(--kt-danger);
  }
}