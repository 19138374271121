.login {
  padding: 0 1rem;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 1) 65%,
      rgba(226, 236, 231, 1) 85%,
      rgba(201, 220, 209, 1) 100%);

  h1 {
    font-size: 1.8rem;
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
      font-size: 2.1rem;
    }

    span {
      font-size: 2.3rem;
      font-weight: 700;

      @include media-breakpoint-up(md) {
        font-size: 3.8rem;
      }
    }
  }
}

.login-form-wrapper {
  width: 100%;
  max-width: 40rem;

  label {
    top: -1.25rem;
    left: 1.25rem;
    background-color: var(--kt-white);
    padding: 0 0.4rem;
    font-size: 1.8rem;
    font-weight: 600;
  }

  input {
    border: 0.15rem solid var(--kt-primary-light);
    border-radius: 1rem;
    padding: 1.8rem 1.25rem;
    width: 100%;
    font-size: 2rem;

    &:focus {
      outline: none;
      border-color: var(--kt-primary);
      background-color: var(--kt-white);
    }
  }

  .required-login {
    color: var(--kt-danger);
    font-weight: 600;
    padding-left: 0.5rem;
  }

  .login-btn {
    padding: 1.5rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    font-weight: 700;
  }
}