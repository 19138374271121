.actions-wrapper {
  position: absolute;
  text-align: left;
  width: max-content;
  font-size: 1.6rem;
  right: 0;
  top: 4.5rem;
  color: var(--kt-primary);
  border: 1px solid var(--kt-primary);
  border-radius: 1rem;
  z-index: 40;
  background-color: var(--kt-white);
  overflow: hidden;

  button:hover svg path {
    fill: var(--kt-white);
  }

  .action-svg-wrapper svg {
    width: 70%;
  }

  .order-action-button {
    font-weight: bold;
    padding: 1.7rem;
    width: 100%;
    display: flex;
    gap: 1.4rem;
    align-items: center;
    border-bottom: 0.05rem solid var(--kt-primary);

    &:hover {
      background-color: var(--kt-primary);
      color: var(--kt-white);
    }
  }
}

.date {
  &-icon {
    width: 4rem;
    height: 4rem;
  }

  &-info {
    border-radius: 0 3rem 3rem 0;
  }

  &-info-content {
    padding: 0.8rem 2rem;
    font-size: 1rem;

    @include media-breakpoint-up(lg) {
      font-size: 2rem;
    }
  }
}

.order-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    color: var(--kt-secondary);
    svg {
      fill: var(--kt-secondary);
    }
  }
}
