.tooltip {
  margin-top: 4rem;
  position: relative;


  &-content {
    background-color: var(--kt-white);
    color: var(--kt-primary);
    text-align: center;
    border-radius: 2rem;
    padding: 3rem;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 100%;
    transform: translate(-3rem, -1rem);
    margin-bottom: 1rem;
    min-width: 25rem;
    box-shadow: var(--hb-box-shadow);


    &::after {
      content: "";
      position: absolute;
      top: 100%;
      top: calc(100% - .1rem);
      left: 2rem;
      border-color: var(--kt-white) transparent transparent;
      border-style: solid;
      border-width: 2rem 2rem 2rem 0;
    }
  }

  &-svg {
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}