// TODO: remove unused scss and improve

.box {
  position: relative;
  background-color: var(--kt-primary-light);
  border: .3rem solid var(--kt-primary);
  border-radius: 3rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;

  &.disabled {
    @include disabled-overlay;
  }


  h3 {
    font-size: 1.8rem;
    line-height: 1.33;
    text-transform: uppercase;
    margin-bottom: 0;
    text-align: left;

    span {
      font-size: 2.2rem;
      font-weight: 800;
      display: block;
    }

    @include media-breakpoint-up(md) {
      font-size: 2.2rem;

      span {
        font-size: 2.8rem;
      }

    }
  }

  h3 {
    margin-bottom: 2.9rem;
  }

  p {
    font-size: 1.6rem;
    line-height: 1.33;
    margin-bottom: 1.5rem;
  }

  p,
  ul {
    text-align: left;
  }

  ul {
    padding-left: 2rem;
  }

  .infos {
    height: 100%;
  }


  &-active {
    background-color: var(--kt-primary);
    color: var(--kt-white);

    .infos {
      border-bottom: .2rem solid var(--kt-white);
    }

    .more {
      color: var(--kt-white);

      &:hover {
        >span {
          color: var(--kt-white);
        }
      }
    }

    .choose {
      background-color: var(--kt-primary);
    }
  }
}