.headline {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;

  span {
    font-weight: 400;
  }

  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  &-primary {
    background-image: linear-gradient(to right, var(--kt-primary) 50%, transparent 50%);
  }

  &-secondary {
    background-image: linear-gradient(to right, var(--kt-secondary) 50%, transparent 50%);
  }

  h2 {
    display: flex;
    gap: 1rem;
    white-space: nowrap;
  }

  &-line {
    display: none;

    @media (min-width: 768px) {
      display: block;
      flex-grow: 1;
      height: 0.2rem;
      background-size: 1.5rem 1px;
      margin-left: 1rem;
    }
  }
}
