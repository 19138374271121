.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &-dialog {
    max-width: 100%;
    padding: 0 2rem;
    max-height: calc(100vh - 6rem);
    min-height: auto;
    margin: 3rem auto;
    border-radius: 3rem;
  }

  @include media-breakpoint-up(lg) {
    &-dialog {
      max-width: 80%;
    }
  }

  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 30rem;
    pointer-events: auto;
    background-color: var(--hb-modal-content-bg);
    background-clip: padding-box;
    border-radius: 3rem;
    outline: 0;
  }

  &-close {
    cursor: pointer;
    display: block;
    width: 3rem;
    height: 3rem;
    position: absolute;
    z-index: 50;
    box-shadow: 0 0.3rem 0.6rem #000000bf;
    border-radius: 50%;
    right: 2rem;
    top: 2rem;
    color: #fff;
    background: #0f796f
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
      center/1em auto no-repeat;
  }

  &::before {
    content: '';
    opacity: var(--hb-modal-backdrop-opacity);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: var(--hb-modal-backdrop-bg);
  }
}
