//
// Root
//

// Light mode
@include theme-light() {
    // Page bg color
    --kt-page-bg: #{$page-bg};
}

// Dark mode
@include theme-dark() {
    // Page bg color
    --kt-page-bg: #{$page-bg-dark};
}