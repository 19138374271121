.aside-profile {
  background-color: var(--kt-primary);
  border-radius: 2rem 0 0 2rem;
  margin-top: 4rem;

  .aside-title {
    font-size: 2rem;
    margin-bottom: 2.2rem;
  }

  .aside-link {
    font-size: 2rem;
    padding-bottom: 4rem;

    &:not(:last-child)::after {
      content: "|";
      margin-left: 1rem;
      margin-right: 1rem;
      font-weight: 100;
      position: relative;
      top: -2px;
    }
  }

  .aside-avatar {
    width: 100%;
    max-width: 13rem;
    position: relative;
    top: -4rem;

    .avatar,
    img {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--kt-primary);
      border-radius: 50%;
      border: 1px solid var(--kt-border-color);
      box-shadow: var(--hb-box-shadow);
      height: 13rem;
      width: 13rem;
    }

    .avatar {
      font-size: 4rem;
      font-weight: 800;
      color: var(--kt-white)
    }
  }
}