.button {
  $button: &;

  &-small {
    padding: 0.8rem 3rem;

    &:hover {
      background-color: var(--kt-primary);
      color: var(--kt-white);
    }
  }

  &-medium {
    padding: 1.2rem 4rem;

    &:hover {
      background-color: var(--kt-primary);
      color: var(--kt-white);
    }
  }

  &-large {
    padding: 1.6rem 5rem;

    &:hover {
      background-color: var(--kt-primary);
      color: var(--kt-white);
    }
  }

  &-spinner {
    display: block;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 0.3rem solid rgb(255 255 255 / 20%);
    border-top-color: rgb(255 255 255 / 80%);
    animation: spin 0.7s infinite linear;

    &-small {
      width: 1.8rem;
      height: 1.8rem;
    }

    &-medium {
      width: 2.4rem;
      height: 2.4rem;
    }

    &-large {
      width: 2.6rem;
      height: 2.6rem;
    }
  }

  &-loading {
    opacity: 0.7;
    pointer-events: none;

    &::after {
      content: '';
      display: block;
      width: 2rem;
      height: 2rem;
      margin-left: .8rem;
      border-radius: 50%;
      border: 2px solid var(--kt-white);
      border-color: var(--kt-white) transparent var(--kt-white) transparent;
      animation: spin 1.2s linear infinite;
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(1turn);
  }
}
