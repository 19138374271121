.loading {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000000;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    backdrop-filter: blur(15px);
    opacity: 1;
    z-index: 0;
  }

  .ajax-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: 0% 0%;
    transform: rotate(90deg) translate(-150%, -50%);
    font-size: 50px;
    width: 1em;
    height: 3em;
    color: var(--kt-secondary);

    .paw {
      width: 1em;
      height: 1em;
      animation: 2050ms pawAnimation ease-in-out infinite;
      opacity: 0;

      svg {
        width: 100%;
        height: 100%;
      }

      .icon {
        fill: currentColor;
      }

      &:nth-child(odd) {
        transform: rotate(-10deg) translate(0, -200%);
      }

      &:nth-child(even) {
        transform: rotate(10deg) translate(100%, -200%);
      }

      @for $i from 1 through 6 {
        &:nth-child(#{$i}) {
          animation-delay: #{ (($i * -1)+6)*0.25 }s;
        }
      }
    }
  }

}

@keyframes pawAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}