//
// Aside Default
//

// General mode(all devices)
.aside {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	padding: 0;

	.aside-header {
		svg {
			display: block;
			max-width: 13rem;
			margin: 0 auto;
			height: auto;
		}
	}

	.aside-footer {
		margin-top: auto;
	}

	.aside-footer-link {
		display: flex;
		padding: 2rem;

		@include media-breakpoint-up(lg) {
			padding: 3rem 2rem 3rem 6rem;
		}
	}
}

// Desktop mode
@include media-breakpoint-up(lg) {
	.aside {
		width: get($aside-config, width);
		transition: width get($aside-config, transition-speed) ease;

		// Aside menu
		.aside-menu {
			width: get($aside-config, width);
		}
	}

	// Aside sticky mode 
	.aside-enabled[data-kt-sticky-aside-sticky="on"] {
		.content {
			padding-left: get($aside-config, width) + get($content-spacing, desktop);
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.aside {
		box-shadow: none;
		border-radius: 0 2rem 2rem 0;
		display: none;
	}
}