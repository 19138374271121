.recipes {
}

.recipe {
  border: 0.3rem solid var(--kt-primary);
  border-radius: 3rem;
}

.product {
  &-cg {
    background-color: var(--hb-product-cg);
  }

  &-gs {
    background-color: var(--hb-product-gs);
  }

  &-rt {
    background-color: var(--hb-product-rt);
  }
}

.slick-slider {
  margin-bottom: 3rem;
}

.slick-dots li button:before {
  color: #e45c41 !important;
  font-size: 1rem !important;
}

// TODO: IMPROVE!!!
.cool-fact {
  color: #000;
  width: 30%;
  min-width: 330px;
  position: relative;
}

.label {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
}

.recipe-modal-input[type='checkbox'] {
  display: none;

  &:checked ~ div.label {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:checked ~ p {
    height: fit-content;
    color: #000;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
  }
}

.recipe-modal-label[for='toggle'] {
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}

p.answer {
  color: transparent;
  width: 100%;
  height: 0;
  margin: 0;
}

.arrow-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  transform: rotate(135deg);
  transition: transform 0.3s ease;
}

.arrow-icon.open {
  transform: rotate(-45deg);
}

// END TODO

input[type='checkbox'] {
  accent-color: #e45c41 !important;
}

.checkbox-input {
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1.5rem;
}

.slick-prev,
.slick-next {
  position: absolute;
  bottom: 5%;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  font-size: 0;
  cursor: pointer;
}

.slick-prev:before,
.slick-next:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='22' height='22'%3E%3Cpath d='M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z' fill='%23ffffff'/%3E%3C/svg%3E");
}

.slick-prev {
  transform: rotate(180deg);
  box-shadow: 0 -3px 6px rgb(0 0 0 / 75%);
  background-color: #00756c;
}

.slick-next {
  box-shadow: 0 3px 6px rgb(0 0 0 / 75%);
  background-color: #00756c;
}

.slick-prev {
  left: 1rem;
}

.slick-next {
  right: 1rem;
}

.slick-dots {
  display: none !important;
}

.recipe-slider {
  width: 35rem;
}

@media screen and (min-width: 767px) {
  .recipe-slider {
    width: 100%;
  }
}
