.cycle {
  svg {
    width: 10rem;
    height: 10rem;
  }

  .graphic {

    .calendar-main,
    .calendar-up,
    .calendar-bg,
    .calendar-num {
      fill: var(--kt-white);
    }

    .calendar-border,
    .calendar-main,
    .calendar-main-border {
      fill: var(--kt-primary);
    }

    .calendar-main-border {
      stroke: var(--kt-primary);
    }
  }

  &-active {
    .graphic {

      .calendar-up,
      .calendar-bg {
        fill: var(--kt-primary);
      }

      .calendar-border,
      .calendar-main-border {
        fill: var(--kt-white);
      }

      .calendar-main-border {
        stroke: var(--kt-white);
      }
    }
  }
}