.menu-panel {
  position: absolute;
  bottom: -0.1rem;
  transform: translateY(100%);
  background-color: var(--kt-white);
  width: 100%;
  box-shadow: var(--hb-box-shadow);
  border-radius: 1rem;
  left: 0;
  right: 0;
  width: 90%;
  display: flex;
  margin: 0 auto;
  z-index: 1;
}

.fill-black {
  fill: var(--kt-black);
}

.menu-dropdown {
  border-radius: 1rem;
  overflow: hidden;
  width: 100%;
}

.menu-panel-link,
.menu-panel-btn {
  font-size: 1.4rem;
  line-height: 1.2;
  color: var(--kt-primary);
  font-weight: 500;
}

.menu-panel-btn {
  text-align: left;
  padding: 1.5rem;
  border-bottom: 1px solid var(--kt-primary);

  &:hover {
    background-color: var(--kt-light);
    color: var(--kt-primary);
  }

  &:last-child {
    border-bottom: none;
  }

  &-active {
    background-color: var(--kt-primary);
    color: var(--kt-white);
    cursor: not-allowed;

    &:hover {
      background-color: var(--kt-primary);
      color: var(--kt-white);
    }
  }
}

.menu-panel-link {
  &-active {
    color: var(--kt-secondary);
  }
}

.menu-panel-button {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.2;
  color: var(--kt-secondary);
  font-weight: 600;
  margin-top: 2rem;
}
