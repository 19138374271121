.widget {
  &-header {
    border-radius: 3rem 3rem 0 0;
    padding: 2.7rem 0;
    text-align: center;
    font-size: 2rem;
    text-transform: uppercase;
    background-color: var(--kt-primary-light);
    color: var(--kt-primary);
  }

  &-footer {
    &-inner {
      color: var(--kt-primary);
      text-transform: uppercase;
      font-weight: 700;
      border-radius: 0 0 3rem 3rem;
      text-align: center;
      padding: 2rem;
    }
  }
}

.widget {
  &-recipe-wrapper {
    border-radius: 0 0 2rem 2rem;
    text-align: center;
  }

  &-recipe-kcal {
    padding: 0.8rem 2.2rem;
    margin-left: -2rem;
    border-radius: 0 2rem 2rem 0;
    text-align: left;
    font-weight: 700;
  }

  &-upcoming-order-cal {
    padding: 0.85rem 0;
    padding-right: 5rem;
    padding-left: 2rem;
    border-radius: 0 3rem 3rem 0;
  }
}
