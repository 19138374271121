// TODO: remove unused scss and improve

.pension {
  &-list {
    list-style-type: disc;
    padding-left: 2rem;
    font-size: 1.6rem;
    line-height: 1.25;
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
  }
}