.input-wrapper {
  margin-bottom: 1.5rem;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  & input {
    border-radius: 0.8rem;
    height: 5.6rem;
    border: 0.1rem solid var(--kt-primary-light);
    padding-left: 1.4rem;
    padding-right: 1rem;
    width: 100% !important;

    &:focus {
      outline: none !important;
      border-color: var(--kt-primary);
    }
  }

  & label {
    position: absolute;
    font-size: 1.4rem !important;
    top: -1rem;
    left: 1rem;
    color: var(--kt-primary);
    background-color: var(--kt-white);
    padding: 0 0.5rem;
  }

  & .required-input {
    color: var(--kt-danger);
  }
}

.password-toggle,
.password-toggle-visible {
  position: absolute;
  right: 1.1rem;
  cursor: pointer;
}

.password-toggle::after {
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg class='password-toggle' id='togglePassword' role='button' width='21px' height='10px' viewBox='0 0 22.673 9.757' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-304.18 -491.98)' fill='none' stroke='%2300756c' stroke-width='2' data-name='Group 3419'%3E%3Cpath transform='translate(-629.46 60.785)' d='m935.76 431.81c10.626 13.571 18.642 0 18.642 0' data-name='Path 10044'/%3E%3Cline transform='translate(304.78 494.84)' x2='3.067' y1='2.3' data-name='Line 177'/%3E%3Cline transform='translate(309.38 497.14)' x2='2.3' y1='3.067' data-name='Line 178'/%3E%3Cline transform='translate(315.51 498.67)' y1='3.067' data-name='Line 179'/%3E%3Cline transform='translate(319.35 497.91)' x1='2.3' y1='3.067' data-name='Line 180'/%3E%3Cline transform='translate(323.18 494.8)' x1='3.067' y1='2.343' data-name='Line 181'/%3E%3C/g%3E%3C/svg%3E");
  display: block;
  width: 2.1rem;
  height: 1rem;
  background-size: 2.1rem 1rem;
}

.password-toggle-visible::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='password-toggle-visible d-none' id='togglePassword' role='button' viewBox='0 0 156.61 69.27' width='21px' height='10px' style='enable-background:new 0 0 156.61 69.27;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%2300756C;stroke-width:5;stroke-linejoin:round;stroke-miterlimit:10;%7D .st1%7Bfill:%2300756C;%7D .st2%7Bfill:none;stroke:%23000000;stroke-miterlimit:10;%7D .st3%7Bfill:none;stroke:%2300756C;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D %3C/style%3E%3Cg%3E%3Cg%3E%3Cpath class='st0' d='M153.01,34.89c-41.28,41.28-108.08,41.28-149.36,0v-0.07c41.28-41.28,108.08-41.28,149.36,0V34.89z'/%3E%3Ccircle class='st0' cx='79.22' cy='34.39' r='30.37'/%3E%3C/g%3E%3Ccircle class='st1' cx='79.58' cy='34' r='12.76'/%3E%3C/g%3E%3C/svg%3E");
}

@media (min-width: 1024px) {
  .input-wrapper {
    margin-bottom: 0;
  }
}
