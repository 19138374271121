.checkbox-wrapper {
  display: block;
  position: relative;
  padding-left: 3.5rem;
  margin-bottom: 1.2rem;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~.checkmark {
        background-color: #00756c;

        &::after {
          display: block;
        }
      }
    }
  }

  & span {
    position: absolute;
    top: 0;
    left: 0;
    height: 2.3rem;
    width: 2.3rem;
    background-color: #fff;
    border-radius: 1.5rem;
    border: 0.2rem solid #00756c;
  }

  .checkmark {
    &:after {
      content: '';
      position: absolute;
      display: none;
      width: 100%;
      height: 100%;
      border: solid white;
      border-radius: 1rem;
    }
  }
}