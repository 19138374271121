.welcome-message {
  &-cloud {
    background-color: var(--kt-primary);
    color: var(--kt-white);
    border-radius: 3rem;
    font-weight: 700;
    padding: 2rem;
    font-size: 1.4rem;
    position: absolute;
    top: 3rem;
    left: 50%;
  }
  .cloud-pointer {
    position: absolute;
    left: 1rem;
    bottom: -3rem;
  }
}
