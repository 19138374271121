.configurator {
  margin-bottom: 5rem;

  &-item {
    width: 100%;
    border-radius: 3rem;
    box-shadow: var(--hb-box-shadow);
  }

  .pet-info {
    &-list {
    }

    &-item {
      font-size: 1.6rem;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }

  .pet-name {
    padding-bottom: 1.7rem;
    font-weight: 600;
    font-size: 1.8rem;
    margin: 0 auto;
  }
}

// RADIO SLIDER
.radio-slider-container {
  padding-bottom: 6rem;
}

.radio-slider {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding-left: 0px;
  height: 20rem;

  .radio-item {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-end;

    &.active {
      color: var(--kt-primary);

      img {
        opacity: 1;
        display: block;
        position: absolute;
        top: 0;
        width: 50%;
        left: 50%;
        transform: translateX(-50%);

        @include media-breakpoint-up(lg) {
          width: 70%;
        }
      }

      label {
        div {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          margin: 15px auto 10px;
          width: 100%;
        }
      }
    }

    &:first-child {
      label {
        &::before {
          margin-left: 0;
        }
      }
    }

    &:last-child {
      label {
        &::before {
          margin-right: 0;
        }
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    width: auto;

    &:checked {
      + label {
        &:before {
          box-shadow: inset 0px 0 0 4px var(--kt-white);
          padding: 12px;
          background-color: var(--kt-primary);
          transform: translateY(+7.5px);
        }
      }
    }

    &:focus {
      + label {
        &:before {
          outline: none;
          border-color: var(--kt-primary);
        }
      }
    }
  }

  img {
    display: none;
  }

  label {
    min-width: 30px;
    font-weight: bold;

    &::before {
      content: '';
      border-radius: 100%;
      background-color: var(--kt-white);
      border: 3px solid var(--kt-primary);
      display: block;
      width: 15px;
      height: 15px;
      position: relative;
      cursor: no-drop;
      z-index: 1;
      margin: 0 auto;
    }

    &::after {
      content: '';
      background: #c9dcd1;
      position: absolute;
      height: 3px;
      width: 100%;
      left: 0;
      z-index: 0;
      top: auto;
      transform: translateY(-7.5px);
    }

    div {
      display: none;
    }
  }

  span {
    &.sub-label {
      margin-top: 0px;
      font-weight: normal;
    }
  }
}
