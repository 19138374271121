.tabs {
  .tab {
  }

  .tab-header {
    cursor: pointer;
    border-bottom: 0.4rem solid transparent;

    &.show {
      color: var(--kt-primary);
      border-bottom: 0.4rem solid var(--kt-primary);

      .tab-button {
        background-color: var(--kt-white);
      }
    }

    h2 {
      font-weight: bold;
    }

    p {
      text-transform: uppercase;
    }
  }

  .tab-button {
    padding: 2.5rem;
  }

  .tab-collapse {
  }

  .tab-body {
  }

  .collapse {
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    visibility: visible;
    height: auto;

    &:not(.show) {
      transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      height: 0;
      visibility: hidden;
    }
  }
}
