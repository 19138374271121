//
// Accordion
//

// Base
.accordion {
  .accordion-header {
    cursor: pointer;
    padding: 1.6rem 1rem;
  }

  .accordion-button {
  }

  .accordion-item {
  }

  .accordion-collapse {
  }

  .accordion-body {
    padding: 0rem 1rem;
  }

  @include media-breakpoint-up(lg) {
    .accordion-body {
      padding: 3rem;
    }

    .accordion-header {
      cursor: pointer;
      padding: 1.6rem 3rem;
    }
  }

  .collapse {
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    visibility: visible;
    display: block;
    height: auto;

    &:not(.show) {
      transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      height: 0;
      visibility: hidden;
      display: none;
    }
  }
}
